// @ts-expect-error https://git.hubteam.com/HubSpot/Outpost/tree/master/outpost library lacks typings
import OutpostErrorReporter from 'outpost/OutpostErrorReporter';
import { debugLog, isDebugEnabled } from './debug';
import { getHublet, getPortalId, isCmsQa } from './cms';
import { isJasmine, isSelenium } from './env';
const MAX_OUTPOST_ERRORS_PER_SESSION = 5;
let errorsReported = 0;
let outpostReporter;
function getOutpostReporter() {
  if (!outpostReporter) {
    outpostReporter = new OutpostErrorReporter('video-embed', {
      env: isCmsQa() ? 'qa' : null,
      debug: isDebugEnabled(),
      tags: {
        domain: window.location.origin,
        portalId: getPortalId() || undefined,
        hublet: getHublet() || undefined
      }
    });
  }
  return outpostReporter;
}
function isOutpostEnabled() {
  return !(isJasmine() || isSelenium() || errorsReported >= MAX_OUTPOST_ERRORS_PER_SESSION);
}
export function reportMessage(message, extraContext = {}) {
  debugLog('Reporting message to outpost', message);
  if (!isOutpostEnabled()) {
    return;
  }
  getOutpostReporter().reportMessage(message, extraContext);
  errorsReported++;
}
export function reportError(err, extraContext = {}) {
  debugLog('Reporting error to outpost', err);
  if (!isOutpostEnabled()) {
    return;
  }
  getOutpostReporter().report(err, extraContext);
  errorsReported++;
}