import { getFullUrlPure } from 'hubspot-url-utils/pure';
import { na1 } from 'hubspot-url-utils/hublets';
const APP_ROUTE = 'outpost';
export const getHubspotReportingUrl = ({
  hublet = na1,
  isQa = false
} = {}) => {
  const url = getFullUrlPure('forms', hublet.length > 0 ? hublet : na1, isQa ? 'qa' : 'prod', {
    domainOverride: 'hubspot'
  });
  return `${url}/${APP_ROUTE}`;
};
export const getEmbedAppReportingUrl = ({
  hublet = na1,
  isQa = false
} = {}) => {
  const url = getFullUrlPure('exceptions', hublet.length > 0 ? hublet : na1, isQa ? 'qa' : 'prod', {
    domainOverride: 'hs-embed-reporting'
  });
  return `${url}/${APP_ROUTE}`;
};