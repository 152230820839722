export function pick(object, keys = []) {
  return Object.keys(object).filter(key => keys.includes(key)).reduce((acculumatedObject, key) => Object.assign({}, acculumatedObject, {
    [key]: object[key]
  }), {});
}
export function objectValues(obj) {
  if (Object.values) {
    return Object.values(obj);
  }
  return Object.keys(obj).map(key => obj[key]);
}