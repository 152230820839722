import { EMAIL_SHARE_PAGE_PATH } from '../constants';
import { getSelfScriptEl, parsePortalIdFromScriptLoader } from './dom';
import { isAuthedAppPreview } from './env';
import { reportMessage } from './errors';
export function getHsVarsRendererContext() {
  return window.hsVars ? window.hsVars : null;
}
export function getPortalId() {
  const scriptEl = getSelfScriptEl();
  if (scriptEl && scriptEl.dataset.hsPortalId) {
    return Number(scriptEl.dataset.hsPortalId);
  }
  if (getHsVarsRendererContext()) {
    return getHsVarsRendererContext().portal_id;
  }
  return parsePortalIdFromScriptLoader();
}
export function isCmsQa() {
  const scriptEl = getSelfScriptEl();
  if (scriptEl && scriptEl.dataset.hsEnv) {
    return scriptEl.dataset.hsEnv === 'qa';
  }
  const hsVars = getHsVarsRendererContext();
  if (hsVars && hsVars.app_hs_base_url) {
    return hsVars.app_hs_base_url.includes('hubspotqa.com');
  }
  return false;
}
export function getHublet() {
  const scriptEl = getSelfScriptEl();
  if (isAuthedAppPreview()) {
    const subdomain = window.location.hostname.split('.')[0];
    if (subdomain.includes('-')) {
      return subdomain.split('-')[1];
    }
  }
  if (scriptEl && scriptEl.dataset.hsRegion) {
    return scriptEl.dataset.hsRegion;
  }
  const hsVars = getHsVarsRendererContext();
  if (hsVars && hsVars.app_hs_base_url) {
    const apiDomain = hsVars.app_hs_base_url.split('//')[1];
    const subdomain = apiDomain.split('.')[0];
    return subdomain.includes('-') ? subdomain.split('-')[1] : null;
  }
  return null;
}
export function getPageId() {
  const scriptEl = getSelfScriptEl();
  if (scriptEl && scriptEl.dataset.hsPageId) {
    return Number(scriptEl.dataset.hsPageId);
  }
  if (getHsVarsRendererContext()) {
    return getHsVarsRendererContext().page_id;
  }
  return null;
}
export function getPageLanguage() {
  if (getHsVarsRendererContext()) {
    return getHsVarsRendererContext().language;
  }
  return null;
}
export function isCmsEditorPreview() {
  return Boolean(window.hsInEditor);
}
export function isEmailSharePage() {
  return window.location.pathname.startsWith(EMAIL_SHARE_PAGE_PATH);
}
const CONTENT_ID_CLASSNAME_PREFIX = 'hs-content-id-';
export function parseContentIdFromBody() {
  let contentId = null;
  document.body.classList.forEach(className => {
    if (className.includes(CONTENT_ID_CLASSNAME_PREFIX)) {
      contentId = parseInt(className.replace(CONTENT_ID_CLASSNAME_PREFIX, ''), 10);
    }
  });
  return contentId || null;
}
export function getGlobalOptions() {
  return window.hsVideoOptions || {};
}
export function getPageMeta() {
  const pageMeta = {
    pageUrl: window.location.href,
    pageTitle: document.title,
    pageId: getPageId()
  };

  // share page is a case where `data-hsv-page-id` attr doesn't get added to our script tag
  if (isEmailSharePage()) {
    pageMeta.pageId = 0;
  }
  if (typeof pageMeta.pageId === 'number') {
    return pageMeta;
  }

  // todo remove fallback path below when we roll out data-hs-page-id on the video-embed script tag
  if (!isAuthedAppPreview()) {
    if (parseContentIdFromBody()) {
      pageMeta.pageId = parseContentIdFromBody();
      reportMessage('Missing expected hsVars context, parsed pageId from body classname');
    } else {
      reportMessage('Missing expected hsVars context');
    }
  }
  return pageMeta;
}